.scrollbarCustom::-webkit-scrollbar{
    width: 5px;
    height: 10px;
}

.scrollbarCustom::-webkit-scrollbar-thumb{
    background: #B3AFB3;
    border-radius: 12px;
}

.scrollbarCustom::-webkit-scrollbar-thumb:hover{
    background: #B3AFB3;
}

.scrollbarCustom::-webkit-scrollbar-track{
    background: #F0F0F0;
    border-radius: 0px;
    box-shadow: inset 0px 0px 0px 0px #F0F0F0;
}


/* * */

.containerProposals{
    max-height: 460px;
    overflow: auto;
}
